import React, { useEffect } from "react";
import "../css/footer.css";
import video2 from "../../assets/video2.mp4";
import { GrLocation } from "react-icons/gr";
import { BsFacebook } from "react-icons/bs";
// import { FiSend } from "react-icons/fi";
// import { MdOutlineTravelExplore } from "react-icons/md";
import {
  AiOutlineTwitter,
  AiFillYoutube,
  AiFillInstagram,
} from "react-icons/ai";
import { RiWhatsappFill } from "react-icons/ri";
import { BsFillTelephoneFill } from "react-icons/bs";
// import { FiChevronRight } from "react-icons/fi";
import img from "../../assets/pegasus.png";

import Aos from "aos";
import "aos/dist/aos.css";

const Footer = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <section id="footer" className="footer">
      <div className="videoDiv">
        <video src={video2} loop autoPlay muted typeof="video/mp4"></video>
      </div>

      <div className="secContent container">
        <div className="contactDiv flex">
          <div data-aos="fade-up" className="text">
            <small>Send your goods with us</small>
            <h2>We prioritize your service and comfort</h2>
          </div>

          {/* <div className="inputDiv flex">
            <input data-aos="fade-up" type="text" placeholder="Enter Email Address" />
            <button data-aos="fade-up" className="btn flex" type="submit">
              SEND <FiSend className="icon" />
            </button>
          </div> */}
        </div>

        <div className="footerCard flex">
          <div className="footerIntro flex">
            <div className="logoDiv">
              <a href="#" className="logo flex">
                {/* <MdOutlineTravelExplore className="icon" /> */}
                <img src={img} className="icon" alt="" />
                Pegasustrans Cargo
              </a>
            </div>

            <div data-aos="fade-up" className="footerParagraph">
              Pegasustrans Cargo is a company that engages in the delivery of
              cargo through air, land, and sea transportation
              <div className="location flex">
                <GrLocation className="icon" />
                <span>Jl. Kebon kacang I No. 32 K Jakarta Pusat</span>
              </div>
              <div className="telp flex">
                <BsFillTelephoneFill className="icon" />
                <span>(021) 2123 4726, (021) 3101 768</span>
              </div>
              <p className="social">Social Media:</p>
            </div>

            <div data-aos="fade-up" className="footerSocials flex">
              <a href="https://www.facebook.com/pegasustransnusantara">
                <BsFacebook className="icon" />
              </a>
              <a href="https://www.youtube.com/@pegasuscargo8556">
                <AiFillYoutube className="icon" />
              </a>
              <a href="https://instagram.com/pegasus_cargo?igshid=YmMyMTA2M2Y=">
                <AiFillInstagram className="icon" />
              </a>
              <a href="https://twitter.com/CargoPegasus?s=20">
                <AiOutlineTwitter className="icon" />
              </a>
              <a href="https://api.whatsapp.com/send?phone=+6282333207117&text=Hai%20Pegasus%20Cargo,%20Tolong%20info%20lebih%20lanjut%20mengenai%20pengiriman%20barang,%20terima%20kasih%20.">
                <RiWhatsappFill className="icon" />
              </a>
              </div>
          </div>

          <div className="footerDiv flex">
            <small>PEGASUSTRANS CARGO</small>
            <small>COPYRIGHTS RESERVED - ZASAI 2023</small>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
