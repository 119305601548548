import React, { useState } from "react";
import "../css/navbar.css";
import { TbGridDots } from "react-icons/tb";
import { AiFillCloseCircle } from "react-icons/ai";
import { GiPegasus } from "react-icons/gi";
import img from "../../assets/brosurpgs.png";

const Navbar = () => {
  const [active, setActive] = useState("navBar");
  const [activeImg, setActiveImg] = useState("imgPrice");

  const showNav = () => {
    setActive("navBar activeNavbar");
  };

  const removeNavbar = () => {
    setActive("navBar");
  };

  const showPrice = () => {
    setActiveImg("imgPrice imgPriceShow");
  };
  const removePrice = () => {
    setActiveImg("imgPrice");
  };

  return (
    <section className="navBarSection">
      <div className={activeImg}>
        <div className="closeContainer">
        <span>Close</span> <AiFillCloseCircle className="icon close" onClick={removePrice} />
        </div>
        <img src={img} alt="" />
      </div>

      <header className="header flex">
        <div className="logoDiv">
          <a href="#" className="logo flex">
            <h1>
              <GiPegasus className="icon" /> Pegasustrans Cargo
            </h1>
          </a>
        </div>

        <div className={active}>
          <ul className="navLists flex">
            <li className="navItem">
              <a href="#" className="navLink">
                Home
              </a>
            </li>

            {/* <li className="navItem">
              <a href="#" className="navLink">
                News
              </a>
            </li> */}

            <li className="navItem">
              <a href="#footer" className="navLink">
                Contact
              </a>
            </li>

            <button className="btn">
              <a href="#" onClick={showPrice}>
                Check The Price
              </a>
            </button>

            <div onClick={removeNavbar} className="closeNavbar">
              <AiFillCloseCircle className="icon" />
            </div>
          </ul>
        </div>

        <div onClick={showNav} className="toggleNavbar">
          <TbGridDots className="icon" />
        </div>
      </header>
    </section>
  );
};

export default Navbar;
