import React,{useEffect} from "react";
import "../css/main.css";
import img from "../../assets/sri.jpg";
import img2 from "../../assets/mks.jpg";
import img3 from "../../assets/mdc.jpg";
import img4 from "../../assets/srg.jpg";
import img5 from "../../assets/ambon.jpg";
import img6 from "../../assets/bju.jpg";
import { HiOutlineLocationMarker} from "react-icons/hi";
import { HiOutlineClipboardCheck} from "react-icons/hi";
import Aos from 'aos'
import'aos/dist/aos.css'

const Data = [
  {
    id: 1,
    imgSrc: img,
    destTitle: "Kalimantan Timur",
    location: "Samarinda",
    grade: "Price for AIR",
    fees: "Rp.36000/kg",
    desc: "Only Shipment by Air(Plane) and Sea (Ship)",
  },
  {
    id: 2,
    imgSrc: img2,
    destTitle: "Sulawesi Selatan",
    location: "Makassar",
    grade: "Price for AIR",
    fees: "Rp.32500/kg",
    desc: "Only Shipment by Air(Plane) and Sea(Ship)",
  },
  {
    id: 3,
    imgSrc: img3,
    destTitle: "Sulawesi Utara",
    location: "Manado",
    grade: "Price for AIR",
    fees: "Rp.46500/kg",
    desc: "Only Shipment by Air(Plane) and Sea(Ship)",
  },
  {
    id: 4,
    imgSrc: img4,
    destTitle: "Jawa Tengah",
    location: "Semarang",
    grade: "Price for LAND",
    fees: "Rp.1300/kg",
    desc: "Only Shipment by land(Truck)",
  },
  {
    id: 5,
    imgSrc: img5,
    destTitle: "Maluku",
    location: "Ambon",
    grade: "Price for AIR",
    fees: "Rp.59500/kg",
    desc: "Only Shipment by Air(Plane) and Sea(Ship)",
  },
  {
    id: 6,
    imgSrc: img6,
    destTitle: "Kalimantan Selatan",
    location: "Banjarbaru",
    grade: "Price for AIR",
    fees: "Rp.28800/kg",
    desc: "Only Shipment by Air(Plane) and Sea(Ship)",
  },
];


const Main = () => {

  useEffect(() => {
    Aos.init({duration:2000})
  } , [])
  


  return (
    <section className="main container section">
      <div className="secTitle">
        <h3 data-aos="fade-right" className="title">Most shipments to the area</h3>
      </div>

      <div className="secContent grid">
        {Data.map(({ id, imgSrc, destTitle, location, grade, fees, desc }) => {
          return (
            <div key={id} data-aos="fade-up" className="singleDestination">
              <div className="imageDiv">
                <img src={imgSrc} alt={destTitle} />
              </div>

              <div className="cardInfo">
                <h4 className="destTitle">{destTitle}</h4>
                <span className="continent flex">
                  <HiOutlineLocationMarker className="icon" />
                  <span className="name">{location}</span>
                </span>

                <div className="fees flex">
                  <div className="grade">
                    <span>
                      {grade}
                    </span>
                  </div>
                  <div className="price">
                    <h5>{fees}</h5>
                  </div>
                </div>

                <div className="desc">
                    <p>{desc}</p>
                </div>

                <button className="btn flex">
                    DETAILS <HiOutlineClipboardCheck className="icon"/>
                </button>

              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Main;
